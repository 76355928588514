import { MenuFoldOutlined, MenuUnfoldOutlined, OrderedListOutlined, ShrinkOutlined, UnorderedListOutlined, VerticalAlignBottomOutlined, WarningFilled } from '@ant-design/icons';
import { Checkbox, Input, Row, Skeleton, Space, Tag, Tooltip, Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { useCallback, useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CardSection from '../../../components/section/CardSection';
import { defaultColorPalette, getColormap } from '../../../compute/colormap';
import { getRecordNameShort, renderColoredTag, spots2peptides, getRecordNameOneLine } from '../../../compute/utils';
import {
  selectColormap,
  selectCurrentItemName,
  selectCurrentRecordID,
  selectExcludedFirstCycleParamState,
  selectExcludedRecordIDs,
  selectRecords,
  selectSubtractItemName,
  setColormap,
  setCurrentItemName,
  setCurrentRecordID,
  setExcludedFirstCycleParamState,
  setExcludedRecordIDs,
  setRecords,
  setSensogramRenderType,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, SensogramRenderType } from '../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arycolor } from '../../../assets/css/color';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';
import { useOktaAuth } from '@okta/okta-react';
import DashboardMeasuresEditMode from './DashboardMeasuresMode/DashboardMeasuresEditMode';
import DashboardMeasuresSelectMode from './DashboardMeasuresMode/DashboardMeasuresSelectMode';
import Cycle1Icon from '../../../assets/images/Cycle1Icon.svg';
import Cycle1ActivateIcon from '../../../assets/images/Cycle1ActivateIcon.svg';

type DashbordMeasuresProps = {
  setIsExpandedMenu: React.Dispatch<React.SetStateAction<boolean>>;
};
const DashbordMeasures: React.FC<DashbordMeasuresProps> = (props) => {
  const { authState } = useOktaAuth();

  const { sessionID } = useParams<{ sessionID: string }>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [filterText, setFilterText] = useState<string>('');

  const [isExpandedAll, setIsExpandedAll] = useState(true);
  const [isShowFullNames, setIsShowFullNames] = useState(false);

  const [isPeptideSetIncoherent, setIsPeptideSetIncoherent] = useState(false);
  const [isEnableStateFirstCycle, setIsEnableStateFirstCycle] = useState(false);

  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const dispatch = useAppDispatch();
  const excludedFirstCycleParamState = useAppSelector(selectExcludedFirstCycleParamState);

  const [editedRecords, setEditedRecords] = useState<{ ID: number; ItemName: string }[]>([]);
  const [editRecords, setEditRecords] = useState<AryRecord[]>([]);
  const [_cmap, setCmap] = useState<Record<string, string>>();

  const records = useAppSelector(selectRecords);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/list_records?session_id=${sessionID}&ts=${Date.now()}`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((records: AryRecord[]) => {
        if (records === null) {
          return;
        }

        var checkedIDs: number[] = [];

        records.forEach((record) => {
          if (!excludedRecordIDs.includes(record.ID)) {
            checkedIDs.push(record.ID);
          }
        });
        let checked: React.Key[] = checkedIDs;
        setCheckedKeys(checked);

        const uniqueLabels = Array.from(new Set(records.map((r) => r.ItemName)));
        uniqueLabels.sort();
        let cmap = getColormap(uniqueLabels);
        dispatch(setColormap(cmap));
        dispatch(setRecords(records));
      });
  }, [authState, sessionID]);

  // first cycle when trigger true
  useEffect(() => {
    if (records === undefined || records === null) return;
    if (excludedFirstCycleParamState === false) return;
    let uniqueRecords: Map<number, string> = new Map();
    records.forEach((r) => {
      if (!Array.from(uniqueRecords.values()).includes(r.ItemName)) uniqueRecords.set(r.ID, r.ItemName);
    });
    const firstCycleId = Array.from(uniqueRecords.keys());
    const newExludeRecordsId = Array.from(new Set([...excludedRecordIDs, ...firstCycleId]));
    dispatch(setExcludedRecordIDs(newExludeRecordsId));
  }, [dispatch, records, excludedFirstCycleParamState]);

  // first cycle when trigger false
  useEffect(() => {
    if (records === undefined || records === null) return;
    if (excludedFirstCycleParamState === true) return;
    let uniqueRecords: Map<number, string> = new Map();
    records.forEach((r) => {
      if (!Array.from(uniqueRecords.values()).includes(r.ItemName)) uniqueRecords.set(r.ID, r.ItemName);
    });

    if (Array.from(uniqueRecords.keys()).every((v) => excludedRecordIDs.includes(v))) dispatch(setExcludedFirstCycleParamState(true));
  }, [dispatch, excludedFirstCycleParamState, excludedRecordIDs, records]);

  // set checkedKeys with excludeRecord
  useEffect(() => {
    if (records === undefined || records === null) return;

    let checkedIDs: React.Key[] = [];
    records.forEach((record) => {
      if (!excludedRecordIDs.includes(record.ID)) {
        checkedIDs.push(record.ID);
      }
    });
    setCheckedKeys(checkedIDs);
  }, [excludedRecordIDs]);

  // update firstCycle when trigger firstCycle Button
  const updateFirstCycle = () => {
    if (records === undefined || records === null) return;
    if (excludedFirstCycleParamState === false) dispatch(setExcludedFirstCycleParamState(true));
    else {
      dispatch(setExcludedFirstCycleParamState(false));
      let uniqueRecords: Map<number, string> = new Map();
      records.forEach((r) => {
        if (!Array.from(uniqueRecords.values()).includes(r.ItemName)) uniqueRecords.set(r.ID, r.ItemName);
      });
      dispatch(setExcludedRecordIDs(excludedRecordIDs.filter((id) => !Array.from(uniqueRecords.keys()).includes(id))));
    }
  };

  const handleSave = (editedRecords: { ID: number; ItemName: string }[], updatedCmap: Record<string, string> | undefined = {}) => {
    if (authState === null || !authState.accessToken || !_cmap) return;

    if (editedRecords.length === 0) {
      setEditMode(false);
      return;
    }

    fetchAuthorizedAPIEndpoint(`/update_records?session_id=${sessionID}&ts=${Date.now()}`, authState, {
      method: 'POST',
      body: JSON.stringify(editedRecords),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((records: AryRecord[]) => {
        if (records === null) return;

        var checkedIDs: number[] = [];

        records.forEach((record) => {
          if (!excludedRecordIDs.includes(record.ID)) {
            checkedIDs.push(record.ID);
          }
        });
        let checked: React.Key[] = checkedIDs;
        setCheckedKeys(checked);

        dispatch(setColormap(updatedCmap));
        dispatch(setRecords(records));
        setEditMode(false);
        setEditedRecords([]);
      });
  };

  const handleUndo = () => {
    setEditedRecords((prevEdits) => {
      if (prevEdits.length === 0) return prevEdits;

      const lastChange = prevEdits[prevEdits.length - 1];
      const newEditedRecords = prevEdits.slice(0, -1);

      setEditRecords((prevEditRecords) =>
        prevEditRecords.map((record) => {
          if (record.ID === lastChange.ID) {
            const originalRecord = records?.find((r) => r.ID === record.ID);
            return originalRecord ? { ...originalRecord } : record;
          }
          return record;
        })
      );

      return newEditedRecords;
    });
  };

  const handleResetAll = () => {
    setEditedRecords([]);
    setEditRecords((prevEditRecords) =>
      prevEditRecords.map((record) => {
        const originalRecord = records?.find((r) => r.ID === record.ID);
        if (originalRecord) {
          return { ...record, ItemName: originalRecord.ItemName };
        }
        return record;
      })
    );
  };

  if (records === undefined || records.length === 0) {
    return (
      <CardSection flexGrow="1" scroll="auto" marginBottom="2%">
        <Row justify="space-between" style={{ marginTop: -10 }}>
          <p style={{ fontWeight: 500, marginBottom: 10 }}>Measurements</p>
          <ShrinkOutlined className="clickable-icon" onClick={() => props.setIsExpandedMenu(false)} />
        </Row>
        <Row align="middle" justify="center" style={{ paddingTop: 100 }}>
          <Skeleton active />
          <Skeleton active />
        </Row>
      </CardSection>
    );
  }

  const uniqueItemNames = Array.from(new Set(records.map((r) => r.ItemName)));
  uniqueItemNames.sort();

  return (
    <CardSection flexGrow="1" scroll="auto" marginBottom="2%">
      <Row justify="space-between" style={{ marginTop: -10 }}>
        <p style={{ fontWeight: 500, marginBottom: 10 }}>{editMode && <Tag color="red">EDIT MODE</Tag>} Measurements</p>
        <Space>
          {editMode && (
            <>
              <Tooltip placement="top" mouseLeaveDelay={0} mouseEnterDelay={0.1} overlay="Remove all changes">
                <FontAwesomeIcon className="clickable-icon" style={{ color: '#9a0808', fontSize: '17pt' }} icon="times" onClick={() => handleResetAll()} />
              </Tooltip>
            </>
          )}
          {editMode && (
            <>
              <Tooltip placement="top" mouseLeaveDelay={0} mouseEnterDelay={0.1} overlay="Undo the last change">
                <FontAwesomeIcon className="clickable-icon" icon="arrow-rotate-left" onClick={() => handleUndo()} />
              </Tooltip>
            </>
          )}

          {!editMode ? (
            <>
              <Tooltip placement="top" mouseLeaveDelay={0} mouseEnterDelay={0.1} overlay="Switch to edit mode">
                <FontAwesomeIcon className="clickable-icon" icon="edit" onClick={() => setEditMode(!editMode)} />
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip placement="top" mouseLeaveDelay={0} mouseEnterDelay={0.1} overlay="Save all changes">
                <FontAwesomeIcon className="clickable-icon" icon="save" onClick={() => handleSave(editedRecords, _cmap)} />
              </Tooltip>
            </>
          )}
          {!editMode && <ShrinkOutlined className="clickable-icon" onClick={() => props.setIsExpandedMenu(false)} />}
        </Space>
      </Row>
      {editMode && (
        <div style={{ marginBottom: 10, fontSize: 13 }}>
          {editedRecords.length} change{editedRecords.length >= 2 ? 's' : ''} pending.
        </div>
      )}

      <Space direction="vertical">
        <Space align="center">
          {!isExpandedAll ? (
            <Tooltip overlay="Collapse all">
              <MenuUnfoldOutlined
                className="clickable-icon"
                style={{ display: 'flex' }}
                onClick={() => {
                  setExpandedKeys([]);
                  setIsExpandedAll(true);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip overlay="Expand all">
              <MenuFoldOutlined
                className="clickable-icon"
                style={{ display: 'flex' }}
                onClick={() => {
                  setExpandedKeys(uniqueItemNames);
                  setIsExpandedAll(false);
                }}
              />
            </Tooltip>
          )}
          {!isShowFullNames ? (
            <Tooltip overlay="Show sequence numbers">
              <OrderedListOutlined
                className="clickable-icon"
                style={{ display: 'flex' }}
                onClick={() => {
                  setIsShowFullNames(true);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip overlay="Hide sequence numbers">
              <UnorderedListOutlined
                className="clickable-icon"
                style={{ display: 'flex' }}
                onClick={() => {
                  setIsShowFullNames(false);
                }}
              />
            </Tooltip>
          )}
          {!editMode && (
            <>
              <Tooltip title="Include 1st cycle">
                {excludedFirstCycleParamState ? (
                  <img
                    src={Cycle1ActivateIcon}
                    alt=""
                    className="clickable-icon"
                    style={{
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                    }}
                    onClick={() => updateFirstCycle()}
                  />
                ) : (
                  <img
                    src={Cycle1Icon}
                    alt=""
                    className="clickable-icon"
                    style={{
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                    }}
                    onClick={() => updateFirstCycle()}
                  />
                )}
                {/* <FontAwesomeIcon icon="Cycle1Icon" className="clickable-icon" style={{ marginLeft: 5, color: excludedFirstCycleParamState ? arycolor.aryBlue : arycolor.aryGreyDark }} onClick={() => updateFirstCycle()} /> */}
              </Tooltip>
              <Input
                style={{ width: '80%', borderRadius: 5, height: 25, display: 'flex' }}
                allowClear={true}
                placeholder="Records"
                onChange={(e) => {
                  setFilterText(e.target.value);
                  if (e.target.value !== '') {
                    setExpandedKeys(uniqueItemNames);
                    setIsExpandedAll(false);
                  }
                }}
              />
            </>
          )}
          {isPeptideSetIncoherent ? (
            <Tooltip overlay="Incoherency in peptide sets - some features may not be available!">
              <WarningFilled style={{ color: 'orange', display: 'flex' }} className="clickable-icon" />
            </Tooltip>
          ) : null}
        </Space>

        {editMode ? (
          <DashboardMeasuresEditMode
            checkedKeys={checkedKeys}
            isShowFullNames={isShowFullNames}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
            editedRecords={editedRecords}
            setEditedRecords={setEditedRecords}
            _cmap={_cmap}
            setCmap={setCmap}
            editRecords={editRecords}
            setEditRecords={setEditRecords}
          />
        ) : (
          <DashboardMeasuresSelectMode
            checkedKeys={checkedKeys}
            setCheckedKeys={setCheckedKeys}
            isShowFullNames={isShowFullNames}
            filterText={filterText}
            setIsPeptideSetIncoherent={setIsPeptideSetIncoherent}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
          />
        )}
      </Space>
    </CardSection>
  );
};

export default DashbordMeasures;
