import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOktaAuth } from '@okta/okta-react';
import { Col, Dropdown, Menu, MenuProps, Row } from 'antd';
import { useEffect, useState } from 'react';
import { FeatureFlag, UserClaimsWithTSDB } from '../../types/userType';
import { WithFeatureFlagsCheck } from '../../with_feature_flags_check';

type HelpWidgetProps = {};

const HelpWidget: React.FC<HelpWidgetProps> = () => {
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  const items: MenuProps['items'] = [
    {
      label: (
        <a href="https://aryballe.helpjuice.com/en_US/digital-olfaction-hub" style={{ lineHeight: '35px' }} target="_blank" rel="noreferrer" className="helpItem">
          <FontAwesomeIcon icon="book-open" style={{ marginRight: 10 }} />
          Search in documentation
        </a>
      ),
      key: '0',
    },
    {
      label: (
        <a href="mailto:support@aryballe.com" style={{ lineHeight: '35px' }} target="_blank" rel="noreferrer" className="helpItem">
          <Row align="middle">
            <FontAwesomeIcon icon="comment-dots" style={{ marginRight: 10 }} />
            <Col>
              <p style={{ marginBottom: 0, lineHeight: '16px' }}>Contact support</p>
              <p style={{ marginBottom: 0, lineHeight: '16px' }}>support@aryballe.com</p>
            </Col>
          </Row>
        </a>
      ),
      key: '1',
    },
  ];

  if (userInfo && userInfo.feature_flags.includes(FeatureFlag.RoleAryballe)) {
    items.push({
      label: (
        <WithFeatureFlagsCheck ff={FeatureFlag.RoleAryballe}>
          <a href="https://aryballe.atlassian.net/servicedesk/customer/portal/1" style={{ lineHeight: '35px', color: 'rgba(0, 0, 0, 0.85)' }} target="_blank" rel="noreferrer" className="helpItem">
            <FontAwesomeIcon icon="hands-helping" style={{ marginRight: 10 }} />
            Send feedback
          </a>
        </WithFeatureFlagsCheck>
      ),
      key: '2',
    });
  }

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']} overlayStyle={{ borderRadius: 5 }}>
        <FontAwesomeIcon icon="question-circle" style={{ width: 20, height: 20, cursor: 'pointer', marginRight: 10 }} />
      </Dropdown>
    </>
  );
};

export default HelpWidget;
