import { Checkbox, Tag, Tooltip, Tree } from 'antd';
import { selectColormap, selectRecords, selectSubtractItemName } from '../../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord } from '../../../../types/analysisTypes';
import { useAppSelector } from '../../../../app/hooks';
import { DataNode } from 'antd/es/tree';
import { useCallback, useEffect, useState } from 'react';
import { getRecordNameOneLine, getRecordNameShort, renderColoredTag, spots2peptides } from '../../../../compute/utils';
import { defaultColorPalette, getColormap } from '../../../../compute/colormap';
import { useParams } from 'react-router-dom';
import Paragraph from 'antd/es/typography/Paragraph';

type DashboardMeasuresEditModeProps = {
  checkedKeys: React.Key[];
  isShowFullNames: boolean;
  expandedKeys: React.Key[];
  setExpandedKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
  editedRecords: { ID: number; ItemName: string }[];
  setEditedRecords: React.Dispatch<React.SetStateAction<{ ID: number; ItemName: string }[]>>;
  _cmap: Record<string, string> | undefined;
  setCmap: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
  editRecords: AryRecord[];
  setEditRecords: React.Dispatch<React.SetStateAction<AryRecord[]>>;
};

const DashboardMeasuresEditMode: React.FC<DashboardMeasuresEditModeProps> = ({ checkedKeys, isShowFullNames, expandedKeys, setExpandedKeys, editedRecords, setEditedRecords, _cmap, setCmap, editRecords, setEditRecords }) => {
  const records = useAppSelector(selectRecords);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const { sessionID } = useParams<{ sessionID: string }>();

  const subtractedItemName = useAppSelector(selectSubtractItemName);
  const cmap = useAppSelector(selectColormap);

  const getNodeTitle = useCallback((record: AryRecord) => (isShowFullNames ? getRecordNameOneLine(record) : getRecordNameShort(record)), [isShowFullNames]);

  useEffect(() => {
    if (!records || records.length === 0) return;
    setEditRecords(records);
    setCmap(cmap);
  }, [records]);

  useEffect(() => {
    setEditRecords((prevEditRecord) => {
      const updated = [...prevEditRecord];

      editedRecords.forEach((ed) => {
        const index = updated.findIndex((r) => r.ID === ed.ID);
        if (index !== -1) {
          updated[index] = { ...updated[index], ItemName: ed.ItemName };
        }
      });

      const uniqueLabels = Array.from(new Set(updated.map((r) => r.ItemName)));
      uniqueLabels.sort();
      let colormap = getColormap(uniqueLabels);
      setCmap(colormap);

      return updated;
    });
  }, [editedRecords]);

  const getRecordInfo = (record: AryRecord, uniquePeptideCodeStrs: string[]) => {
    return (
      <>
        <b>Timestamp:</b> {new Date(record.AbsoluteTimestamp * 1e3).toLocaleString()}
        <br />
        <b>Device:</b> {record.DeviceID}
        <br />
        <b>RunID:</b> {record.RunID}
        <br />
        <b>Peptides:</b> {uniquePeptideCodeStrs.length} on {record.Sensors.length} spots
      </>
    );
  };

  const getRecordTitleNode = (record: AryRecord, _cmap: Record<string, string>, recordTitle: string, uniquePeptideCodeStrs: string[]) => {
    return (
      <Tooltip placement="right" mouseLeaveDelay={0} mouseEnterDelay={0.1} overlay={getRecordInfo(record, uniquePeptideCodeStrs)} styles={{ body: { minWidth: '300px' } }}>
        <Paragraph
          style={{ marginBottom: 0 }}
          editable={{
            tooltip: 'Click to edit sample name',
            onChange: (newItemName) => {
              if (!(newItemName = newItemName.trim().replace(/\s*#\d+$/, ''))) return;

              setEditedRecords((prevRecords) => {
                const updated = [...prevRecords];
                const index = updated.findIndex((el) => el.ID === record.ID);

                if (index !== -1) updated[index] = { ...updated[index], ItemName: newItemName };
                else updated.push({ ID: record.ID, ItemName: newItemName });

                return updated;
              });
            },
            triggerType: ['text'],
          }}
        >
          {recordTitle}
        </Paragraph>
      </Tooltip>
    );
  };

  const getItemTitleNode = (itemName: string, _cmap: Record<string, string>, defaultColorPalette: Record<string, string>, updateItemNameForGroup: (oldName: string, newName: string) => void): React.ReactNode => {
    return (
      <Paragraph
        editable={{
          tooltip: 'Click to edit item name',
          triggerType: ['text'],
          onChange: (newItemName: string) => {
            if (!(newItemName = newItemName.trim().replace(/\s*#\d+$/, ''))) return;
            updateItemNameForGroup(itemName, newItemName);
          },
        }}
        style={{ marginBottom: 0 }}
      >
        {renderColoredTag(defaultColorPalette[_cmap[itemName]], <>{itemName}</>)}
      </Paragraph>
    );
  };

  const updateItemNameForGroup = (oldItemName: string, newItemName: string) => {
    const matchingRecords = editRecords.filter((r) => r.ItemName === oldItemName);

    setEditedRecords((prev) => {
      const filtered = prev.filter((entry) => !matchingRecords.some((r) => r.ID === entry.ID));
      const newEntries = matchingRecords.map((r) => ({ ID: r.ID, ItemName: newItemName }));
      return [...filtered, ...newEntries];
    });
  };

  // Set treeData
  useEffect(() => {
    let _treeData: DataNode[] = [];
    if (editRecords === undefined || editRecords.length === 0 || _cmap === undefined) return;

    // Keep track of uniquePeptideSets
    const _records = [...editRecords].sort((a: any, b: any) => a.ItemName.localeCompare(b.ItemName));

    _records.forEach((record) => {
      var recordTitle = getNodeTitle(record);
      var [peptideCodeStrs] = spots2peptides(record.Sensors);
      var uniquePeptideCodeStrsSet = new Set(peptideCodeStrs);
      let uniquePeptideCodeStrs = Array.from(uniquePeptideCodeStrsSet);

      const recordTitleNode = getRecordTitleNode(record, _cmap, recordTitle, uniquePeptideCodeStrs);

      for (let i = 0; i < _treeData.length; i++) {
        let node = _treeData[i];
        if (node.key === record.ItemName) {
          if (node.children === undefined) {
            node.children = [];
          }
          node.children.push({
            style: {},
            key: record.ID,
            className: `checkbox-${_cmap[record.ItemName]}`,
            title: recordTitleNode,
          } as DataNode);
          return;
        }
      }
      _treeData.push({
        title: getItemTitleNode(record.ItemName, _cmap, defaultColorPalette, updateItemNameForGroup),
        className: `checkbox-${_cmap[record.ItemName]}`,
        key: record.ItemName,
        children: [
          {
            key: record.ID,
            style: {},
            className: `checkbox-${_cmap[record.ItemName]}`,
            title: recordTitleNode,
          } as DataNode,
        ],
      });
    });
    setTreeData(_treeData);
  }, [sessionID, isShowFullNames, _cmap, subtractedItemName, editRecords]);

  if (editRecords === undefined || editRecords.length === 0) return null;

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div>
          <Checkbox checked={checkedKeys.length !== 0} indeterminate={checkedKeys.length > 0 && checkedKeys.length < editRecords.length} />
        </div>
        <div style={{ marginLeft: 10, cursor: 'pointer' }}>
          <Tag>Dataset</Tag>
        </div>
      </div>
      <Tree
        checkable={true}
        showIcon={false}
        expandedKeys={expandedKeys}
        onExpand={(keys) => {
          setExpandedKeys(keys);
        }}
        checkedKeys={checkedKeys}
        treeData={treeData}
      />
    </div>
  );
};

export default DashboardMeasuresEditMode;
