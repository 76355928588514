import tinycolor from 'tinycolor2';
import { ComparisonMeasuresType } from '../types/analysisTypes';

export const peptideColorPaletteVdW: Record<number, string> = {
  // POR1
  19: '#A5EB63',
  20: '#E08B4C',
  22: '#E0535F',
  23: '#FA2655',
  25: '#317ADE',
  26: '#4BFA97',
  27: '#62E051',
  28: '#FAB643',
  29: '#F57656',
  30: '#C232FA',
  55: '#6A31DE',
  62: '#424BF5',
  63: '#FAC44B',
  66: '#F5E322',

  0: '#BDBDBD',
  1: '#969696',
  10: '#525252',

  // POR2
  21: '#4BD9F9',
  18: '#FFBF75',
  65: '#91CD43',
  54: '#EA279C',
  24: '#EA8383',
  64: '#BD00FF',

  // POR4
  34: '#FF00FF', // Magenta vif
  36: '#00FFFF', // Bleu cyan vif
  105: '#FF9900', // Orange doré
  106: '#228B22', // Vert sapin
  224: '#FF0066', // Rose vif
  234: '#1811a8', // Bleu ciel lumineux
  244: '#FF3300', // Rouge feu
  254: '#66FF33', // Vert citron
};

export const DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE = '#722ED1';

export const nonStandardPeptidesColor: string[] = [
  '#ff7f0e', // orange
  '#2ca02c', // vert
  '#8c56ff', // violet
  '#17beff', // bleu ciel
  '#d62728', // rouge
  '#8c564b', // marron
  '#bcbd22', // vert pomme
  '#17becf', // bleu
  '#ff7fff', // rose fushia
  '#083B8B', // bleu
  '#d627ff', // violet fushia
  '#BD8167', // marron clair
  '#0072C0', // bleu foncer
  '#F46F51', // saumon
  '#7f7fff', // violet
  '#1fffb4', // vert
  '#FFD12C', // jaune
  '#7fff7f', // vert
  '#5A1F1F', // bordeau
  '#17ffcf', // vert  bleu
  '#3FA190', // vert sapin
];

export const chemicalFamiliesColorPalette: Record<string, string> = {
  Amine: '#fff566',
  Ether: '#87e8de',
  Hydrocarbon: '#d9d9d9',
  Ketone: '#d3adf7',
  Acid: '##ffe58f',
  Aromatic: '#b7eb8f',
  Sulfurous: '#ffd666',
  Ester: '#95de64',
  CyclicEther: '#e6fffb',
  Nitrile: '#ffd8bf',
  Aldehyde: '#bae7ff',
  Pyrazine: '#adc6ff',
  Alcohol: '#597ef7',
};

export const colorToRgba = (c: number[], a: number): string => {
  const [r, g, b] = c;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

// new colormap shame

export const aryballeColorPalette = {
  pink: '#fd4282',
  yellow: '#ffc500',
  cyan: '#4de5db',
  blue: '#1193f5',
  purple: '#7451f1',

  white: '#ffffff',
  gray: '#9aa3b4',
  grayDark: '#464646',
  blueDark: '#070623',
};

// This object must correspond to list of color in colormap.sass file
export let defaultColorPalette: Record<string, string> = {
  magenta: '#EB2F96',
  geekblue: '#2F54EB',
  volcano: '#FA541C',
  cyan: '#13C2C2',
  gold: '#FAAD14',
  lime: '#A0D911',
  orange: '#FA8C16',
  blue: '#1890FF',
  red: '#F5222D',
  purple: '#722ED1',
  green: '#52C41A',
  maroon: '#800000',
  brown: '#9A6324',
  teal: '#469990',
  navy: '#000075',
};

export const shadeColor = (h: string, p: number): string => {
  const splitColorBase: RegExpMatchArray | null = h.match('#(..)(..)(..)');
  if (splitColorBase === null) return '#3c3c3c';
  const rgbOfColor: string[] = splitColorBase.slice(1);

  const newColor = rgbOfColor
    .map((value: string) => {
      const rgbColor: number = parseInt(value, 16);
      return Math.floor(p > 0 ? (255 - rgbColor) * p + rgbColor : rgbColor * (1 + p));
    })
    .map((newRgb) => newRgb.toString(16).padStart(2, '0'))
    .join('');
  return newColor;
};

export const getColors = () => {
  const tagColors = Object.keys(defaultColorPalette);
  const test: Record<string, string> = {};
  tagColors.map((item) => (test[`${item}Light`] = tinycolor(defaultColorPalette[item]).lighten(20).toHexString()));
  tagColors.map((item) => (test[`${item}Dark`] = tinycolor(defaultColorPalette[item]).darken(15).toHexString()));
  return test;
};

defaultColorPalette = { ...defaultColorPalette, ...getColors() };

export const getColormap = (labels: string[]): Record<string, string> => {
  const tagColors = Object.keys(defaultColorPalette);
  const colormap: Record<string, string> = {};
  labels.sort();
  labels.forEach((l, i) => (colormap[l] = tagColors[i % tagColors.length]));
  return colormap;
};

export const addColorsForNewLabels = (existingCmap: Record<string, string> | undefined, newLabels: string[]): Record<string, string> => {
  const tagColors = Object.keys(defaultColorPalette);

  const newCmap = { ...existingCmap };
  const usedCount = Object.keys(newCmap).length;

  let i = usedCount;
  newLabels.forEach((label) => {
    if (!newCmap[label]) {
      newCmap[label] = tagColors[i % tagColors.length];
      i++;
    }
  });

  return newCmap;
};

export const colorToTransparent = (h: string, p: number) => {
  return `${h}${p.toString().padStart(2, '0')}`;
};

export const getColormapQualityControl = (labels: string[], comparisonMeasureType: string): Record<string, string> => {
  let colorBase: string = '';
  // if (comparisonMeasureType === ComparisonMeasuresType.Reference) colorBase = '#3a73b5';
  if (comparisonMeasureType === ComparisonMeasuresType.Reference) colorBase = '#2F54EB';
  else if (comparisonMeasureType === ComparisonMeasuresType.Test) colorBase = '#fc5305';
  // else if (comparisonMeasureType === ComparisonMeasuresType.Test) colorBase = '#983ab5';eb13ad
  else if (comparisonMeasureType === ComparisonMeasuresType.Other) colorBase = '#3ab586';

  const COLORSPACE = 10; //
  const COLORSPINSPACE = 10; //

  const colormap: Record<string, string> = {};
  labels.sort();
  const SIZE = labels.length;
  const MAXCOLORSPACE = (COLORSPACE * (SIZE - 1)) / 2;
  const MAXCOLORSPINSPACE = (COLORSPINSPACE * (SIZE - 1)) / 2;

  let a = -MAXCOLORSPACE;
  let b = -MAXCOLORSPINSPACE;
  for (let i = 0; i < labels.length; i++) {
    if (a >= 0) colormap[labels[i]] = tinycolor(colorBase).spin(b).darken(a).toHexString();
    else colormap[labels[i]] = tinycolor(colorBase).spin(b).saturate(-a).toHexString();
    a += COLORSPACE;
    b += COLORSPINSPACE;
  }

  return colormap;
};
