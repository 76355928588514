import { Card } from 'antd';
import { ReactNode } from 'react';

export type CardSectionProps = {
  children: ReactNode;
  scroll?: 'auto' | 'scroll' | 'hidden';
  position?: 'fixed' | 'static' | 'relative' | 'sticky' | 'relative';
  top?: number | string;
  height?: number | string;
  marginBottom?: number | string;
  minHeight?: number | string;
  id?: string;
  flexGrow?: string;
  backgroundColor?: string;
  hoverable?: boolean;
};

const CardSection: React.FC<CardSectionProps> = ({ children, scroll, position, top, height, marginBottom, minHeight, id, flexGrow, backgroundColor, hoverable }) => {
  const style = {
    borderRadius: 10,
    background: backgroundColor,
    boxShadow: '4px 4px 4px 0 #07062310',
    overflowY: scroll,
    position: position,
    top: top,
    height: height,
    marginBottom: marginBottom,
    minHeight: minHeight,
    flexGrow: flexGrow,
  };
  return (
    <Card style={style} id={id} styles={{ body: { height: '100%' } }} hoverable={hoverable}>
      {children}
    </Card>
  );
};

CardSection.defaultProps = {
  position: 'static',
  height: 'auto',
  backgroundColor: 'white',
  hoverable: false,
};

export default CardSection;
