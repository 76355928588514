import { Alert, Button, Col, Dropdown, Menu, Popover, Row, Space, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectAggregatePeptides, selectBoundariesMap, selectExcludedRecordIDs, selectHumidityCompensationCalibrantName, selectSubtractItemName } from '../../../features/analysisConfig/analysisConfigSlice';
import { AuxiliarySensorPrettyNameMap, IntensityFigureType, IntensitySource, IntensityType } from '../../../types/analysisTypes';
import { ArrowsAltOutlined, DownOutlined } from '@ant-design/icons';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';
import IntensityFigure from './IntensityFigure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const IntensityPanel: FC<{ sessionID: string; defaultIntensitySource?: IntensitySource; disabledPdf?: boolean; qualityControlMode?: boolean }> = (props) => {
  const { sessionID, disabledPdf, qualityControlMode } = props;
  const defaultIntensitySource = props.defaultIntensitySource ?? +IntensitySource.Signature;

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  const [eligibleIntensitySources, setEligibleIntensitySources] = useState<IntensitySource[] | null>(null);

  const boundariesMap = useAppSelector(selectBoundariesMap);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);

  const [intensitySource, setIntensitySource] = useState<IntensitySource>(defaultIntensitySource);
  const [intensityType, setIntensityType] = useState<IntensityType>(+IntensityType.Average);
  const [figureType, setFigureType] = useState<IntensityFigureType>(+IntensityFigureType.Bar);
  const [error, setError] = useState<string | undefined>();

  const { authState } = useOktaOrQueryAuth();

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/eligible_intensity_sources?session_id=${sessionID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        boundariesMap,
        excludedRecordIDs,
        aggregatePeptides,
        subtractItemName,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((receivedIntensitySources: IntensitySource[]) => {
        if (receivedIntensitySources === null || receivedIntensitySources.length === 0) setError('No eligible intensity sources');
        setEligibleIntensitySources(receivedIntensitySources);
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [authState]);

  const getIntensityTypeItems = () => {
    let allItems = Object.entries(IntensityType).filter(([_, value]) => typeof value === 'number');
    let newItems = allItems;
    if (intensitySource === IntensitySource.Signature) newItems = allItems.filter(([label, value]) => value === IntensityType.Average);
    return newItems.map(([label, value]) => {
      return {
        key: +value,
        label: label,
        onClick: () => {
          setIntensityType(+value);
        },
      };
    });
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      {!disabledPdf && (
        <h3 style={{ width: '100%', textAlign: 'center', marginBottom: 10 }} className="pdf-invisible">
          Intensity
        </h3>
      )}
      {(error !== undefined && error !== '') || eligibleIntensitySources === null ? (
        <Alert type="error" message={error} style={{ marginTop: 10, borderRadius: 5 }} />
      ) : (
        <>
          <Row justify="space-between" align="middle" className="pdf-invisible" style={{ width: '100%' }}>
            <Col></Col>
            <Col>
              <Space align="center" style={{ display: 'flex', justifyContent: 'center' }}>
                <Dropdown
                  menu={{
                    items: eligibleIntensitySources.map((eligibleIntensitySource) => {
                      return {
                        key: +eligibleIntensitySource,
                        label: AuxiliarySensorPrettyNameMap[+eligibleIntensitySource].name,
                        onClick: () => {
                          setIntensitySource(+eligibleIntensitySource);
                        },
                      };
                    }),
                  }}
                >
                  <Tooltip title="Intensity source">
                    <Button style={{ borderRadius: '5px' }}>
                      <Space>
                        {AuxiliarySensorPrettyNameMap[+intensitySource].name}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Tooltip>
                </Dropdown>
                <Dropdown menu={{ items: getIntensityTypeItems() }}>
                  <Tooltip title="Intensity type">
                    <Button style={{ borderRadius: '5px' }}>
                      <Space>
                        {IntensityType[intensityType]}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Tooltip>
                </Dropdown>
                <Dropdown
                  menu={{
                    items: Object.entries(IntensityFigureType)
                      .filter(([_, value]) => typeof value === 'number')
                      .map(([label, value]) => {
                        return {
                          key: +value,
                          label: label,
                          onClick: () => {
                            setFigureType(+value);
                          },
                        };
                      }),
                  }}
                >
                  <Tooltip title="Chart type">
                    <Button style={{ borderRadius: '5px' }}>
                      <Space>
                        {IntensityFigureType[figureType]}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Tooltip>
                </Dropdown>
              </Space>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              {humidityCalibrationCalibrantName && intensitySource === IntensitySource.Signature && (
                <Popover style={{ display: 'flex' }} trigger={'hover'} content="Humidity correction is active">
                  <FontAwesomeIcon icon="droplet" style={{ marginRight: 10, fontSize: '13pt' }} />
                </Popover>
              )}
              {!disabledPdf && <ArrowsAltOutlined className="clickable-icon" onClick={() => setIsVisibleModal(true)} />}
            </Col>
          </Row>

          <IntensityFigure isVisibleModal={isVisibleModal} setIsVisibleModal={setIsVisibleModal} intensitySource={intensitySource} intensityType={intensityType} figureType={figureType} qualityControlMode={qualityControlMode ? true : undefined} />
        </>
      )}
    </div>
  );
};
