import { Dispatch, ReactNode, useState } from 'react';
import { Layout, Menu } from 'antd';

import { defaultMenuRoute, MenuEntry, sideMenuContent } from '../../routes';
import { AppLogo } from './AppLogo';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arycolor } from '../../assets/css/color';
import { useMediaQuery } from '../../App';

type propsType = {
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<React.SetStateAction<boolean>>;
};

export const AppMenu: React.FC<propsType> = ({ isCollapsed, setIsCollapsed }) => {
  const [isActive, setIsActive] = useState<string>(sideMenuContent[0].label);
  const isRowBased = useMediaQuery('(max-width: 768px)');

  const style = {
    desktop: {
      height: '100vh',
      left: 0,
      top: 0,
      bottom: 0,
    },
    phone: {
      left: 0,
      right: 0,
      bottom: 0,
    },
    menuDesktop: {
      height: '100%',
      width: '100%',
      justifyContent: 'space-between',
      display: 'flex',
    },
    menuPhone: {
      height: '100%',
      width: '100%',
      justifyContent: 'space-around',
      display: 'flex',
    },
    ulMenuPhone: {
      display: isRowBased ? 'flex' : 'initial',
      justifyContent: isRowBased ? 'space-around' : 'initial',
      alignItems: isRowBased ? 'center' : 'initial',
    },
    liMenuPhone: {
      display: 'flex',
      alignItems: 'center',
    },
  };
  return (
    <Layout.Sider
      collapsed={isCollapsed}
      collapsible={isRowBased ? false : true}
      onCollapse={(collapsed) => setIsCollapsed(collapsed)}
      collapsedWidth={isRowBased ? '100%' : '60px'}
      width="240px"
      style={isRowBased ? { ...style.phone, ...{ position: 'fixed', zIndex: 3000, overflow: 'auto' } } : { ...style.desktop, ...{ position: 'fixed', zIndex: 3, overflow: 'auto' } }}
    >
      <div style={isRowBased ? { ...style.menuPhone, ...{ flexDirection: 'row' } } : { ...style.menuDesktop, ...{ flexDirection: 'column' } }}>
        {isRowBased === false && <AppLogo showTitle={!isCollapsed} />}

        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Menu defaultSelectedKeys={[defaultMenuRoute]} mode="inline" theme="dark" style={{ width: '100%' }}>
            <div style={style.ulMenuPhone}>{sideMenuContent.map((entry) => AppMenuItem(entry, isCollapsed, setIsActive, isActive, style))}</div>
          </Menu>
        </div>
        <div />
      </div>
    </Layout.Sider>
  );
};

/**
 * This is not a component but a helper function to handle the recursive nature
 * of Menu children.
 * IMPORTANT: Use as a function only, this cannot be used as a component because
 * of how Ant Design expects Menu.Item to be directly under a Menu or Menu.SubMenu
 * instance.
 */
function AppMenuItem(entry: MenuEntry, isCollapsed: boolean, setIsActive: Dispatch<React.SetStateAction<string>>, isActive: string, style: any): JSX.Element {
  const itemIcon: ReactNode = <FontAwesomeIcon icon={entry.icon} style={{ fontSize: '14pt', display: 'block', color: isActive === entry.label ? arycolor.aryCyan : 'white' }} />;

  return (
    <Link to={entry.route} key={entry.route}>
      <Menu.Item key={entry.route} icon={itemIcon} style={{ ...style.liMenuPhone, ...{ paddingLeft: 20, paddingRight: 20, background: 'none' } }} onClick={() => setIsActive(entry.label)}>
        {!isCollapsed && entry.label}
      </Menu.Item>
    </Link>
  );
}
