import { Checkbox, Tag, Tooltip, Tree } from 'antd';
import {
  selectColormap,
  selectCurrentItemName,
  selectCurrentRecordID,
  selectExcludedFirstCycleParamState,
  selectRecords,
  selectSubtractItemName,
  setCurrentItemName,
  setCurrentRecordID,
  setExcludedFirstCycleParamState,
  setExcludedRecordIDs,
  setSensogramRenderType,
} from '../../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, SensogramRenderType } from '../../../../types/analysisTypes';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Highlighter from 'react-highlight-words';
import { DataNode } from 'antd/es/tree';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { getRecordNameOneLine, getRecordNameShort, renderColoredTag, spots2peptides } from '../../../../compute/utils';
import { defaultColorPalette } from '../../../../compute/colormap';
import { useParams } from 'react-router-dom';

type DashboardMeasuresSelectModeProps = {
  setCheckedKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
  checkedKeys: React.Key[];
  isShowFullNames: boolean;
  filterText: string;
  setIsPeptideSetIncoherent: React.Dispatch<React.SetStateAction<boolean>>;
  expandedKeys: React.Key[];
  setExpandedKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
};

const DashboardMeasuresSelectMode: React.FC<DashboardMeasuresSelectModeProps> = ({ setCheckedKeys, checkedKeys, isShowFullNames, filterText, setIsPeptideSetIncoherent, expandedKeys, setExpandedKeys }) => {
  const dispatch = useAppDispatch();
  const excludedFirstCycleParamState = useAppSelector(selectExcludedFirstCycleParamState);
  const records = useAppSelector(selectRecords);
  const currentRecordID = useAppSelector(selectCurrentRecordID);
  const currentItemName = useAppSelector(selectCurrentItemName);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const { sessionID } = useParams<{ sessionID: string }>();

  const subtractedItemName = useAppSelector(selectSubtractItemName);
  const cmap = useAppSelector(selectColormap);
  const getNodeTitle = useCallback((record: AryRecord) => (isShowFullNames ? getRecordNameOneLine(record) : getRecordNameShort(record)), [isShowFullNames]);

  const getRecordInfo = (record: AryRecord, uniquePeptideCodeStrs: string[]) => {
    return (
      <>
        <b>Timestamp:</b> {new Date(record.AbsoluteTimestamp * 1e3).toLocaleString()}
        <br />
        <b>Device:</b> {record.DeviceID}
        <br />
        <b>RunID:</b> {record.RunID}
        <br />
        <b>Peptides:</b> {uniquePeptideCodeStrs.length} on {record.Sensors.length} spots
      </>
    );
  };

  const getRecordTitleNode = (record: AryRecord, cmap: Record<string, string>, currentRecordID: number, recordTitle: string, uniquePeptideCodeStrs: string[]) => {
    return (
      <Tooltip placement="right" mouseLeaveDelay={0} mouseEnterDelay={0.1} overlay={getRecordInfo(record, uniquePeptideCodeStrs)} styles={{ body: { minWidth: '300px' } }}>
        {recordTitle}
        {record.ID === currentRecordID && <span style={{ width: 13, height: 13, marginLeft: 10, marginBottom: -2, borderRadius: '50%', background: defaultColorPalette[cmap[record.ItemName]], display: 'inline-block' }}></span>}
      </Tooltip>
    );
  };

  // Set treeData
  useEffect(() => {
    let _treeData: DataNode[] = [];
    if (records === undefined || records.length === 0 || cmap === undefined) return;

    // Keep track of uniquePeptideSets
    var commonUniquePeptidesNb: number = 0;
    const _records = [...records].sort((a: any, b: any) => a.ItemName.localeCompare(b.ItemName));

    _records.forEach((record) => {
      var recordTitle = getNodeTitle(record);
      var [peptideCodeStrs] = spots2peptides(record.Sensors);
      var uniquePeptideCodeStrsSet = new Set(peptideCodeStrs);
      let uniquePeptideCodeStrs = Array.from(uniquePeptideCodeStrsSet);

      if (commonUniquePeptidesNb === 0) commonUniquePeptidesNb = uniquePeptideCodeStrs.length;
      if (commonUniquePeptidesNb !== uniquePeptideCodeStrs.length) setIsPeptideSetIncoherent(true);

      const recordTitleNode = getRecordTitleNode(record, cmap, currentRecordID, recordTitle, uniquePeptideCodeStrs);

      var isRecordOutfiltered = false;
      if (filterText !== '') {
        let filterRe = new RegExp(filterText, 'gi');
        if (!recordTitle.match(filterRe)) {
          isRecordOutfiltered = true;
        }
      }
      for (let i = 0; i < _treeData.length; i++) {
        let node = _treeData[i];
        if (node.key === record.ItemName) {
          if (node.children === undefined) {
            node.children = [];
          }
          node.children.push({
            style: isRecordOutfiltered ? { display: 'none' } : {},
            key: record.ID,
            className: `checkbox-${cmap[record.ItemName]}`,
            title:
              filterText === '' ? (
                recordTitleNode
              ) : (
                <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[filterText]} autoEscape textToHighlight={recordTitle}>
                  {recordTitleNode}
                </Highlighter>
              ),
          } as DataNode);
          return;
        }
      }
      _treeData.push({
        title: (
          <div>
            {renderColoredTag(
              defaultColorPalette[cmap[record.ItemName]],
              <>
                {record.ItemName}
                {subtractedItemName === record.ItemName && (
                  <Tooltip
                    overlay={
                      <>
                        Average sensogram of this item (its selected records) is subtracted from senosograms of other items
                        <br />
                        <br />
                        This item is excluded from signature analysis
                      </>
                    }
                  >
                    <VerticalAlignBottomOutlined />
                  </Tooltip>
                )}
              </>
            )}
          </div>
        ),
        className: `checkbox-${cmap[record.ItemName]}`,
        key: record.ItemName,
        children: [
          {
            key: record.ID,
            style: isRecordOutfiltered ? { display: 'none' } : {},
            className: `checkbox-${cmap[record.ItemName]}`,
            title:
              filterText === '' ? (
                recordTitleNode
              ) : (
                <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[filterText]} autoEscape textToHighlight={recordTitle}>
                  {recordTitleNode}
                </Highlighter>
              ),
          } as DataNode,
        ],
      });
    });
    setTreeData(_treeData);
  }, [sessionID, filterText, isShowFullNames, cmap, subtractedItemName, currentRecordID, currentItemName]);

  if (records === undefined || records.length === 0) return null;

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div>
          <Checkbox
            checked={checkedKeys.length !== 0}
            indeterminate={checkedKeys.length > 0 && checkedKeys.length < records.length}
            onChange={(e) => {
              if (e.target.checked) {
                setCheckedKeys(records.map((r) => r.ID));
              } else {
                setCheckedKeys([]);
              }
            }}
          />
        </div>
        <div style={{ marginLeft: 10, cursor: 'pointer' }}>
          <Tag
            onClick={() => {
              dispatch(setSensogramRenderType(SensogramRenderType.Dataset));
            }}
          >
            Dataset
          </Tag>
        </div>
      </div>
      <Tree
        checkable={true}
        showIcon={false}
        expandedKeys={expandedKeys}
        onExpand={(keys) => {
          setExpandedKeys(keys);
        }}
        selectedKeys={[currentRecordID]}
        onSelect={(keys, info) => {
          if (keys.length > 0) {
            let key = keys[0];
            if (typeof key.valueOf() === 'string') {
              dispatch(setSensogramRenderType(SensogramRenderType.Item));
              dispatch(setCurrentItemName(key as string));
              dispatch(setCurrentRecordID(-1));
              return;
            }
            dispatch(setSensogramRenderType(SensogramRenderType.Record));
            dispatch(setCurrentItemName(''));
            dispatch(setCurrentRecordID(key as number));
            return;
          }
        }}
        checkedKeys={checkedKeys}
        onCheck={(currentlyCheckedKeys) => {
          var _keys = currentlyCheckedKeys as React.Key[];
          var _excludedRecordIDs: number[] = [];
          let uniqueRecords: Map<number, string> = new Map();

          setCheckedKeys(_keys);
          records.forEach((record) => {
            let key = record.ID;
            if (!_keys.includes(key)) {
              _excludedRecordIDs.push(record.ID);
            }
            if (excludedFirstCycleParamState === true) {
              if (!Array.from(uniqueRecords.values()).includes(record.ItemName)) uniqueRecords.set(record.ID, record.ItemName);
            }
          });
          dispatch(setExcludedRecordIDs(_excludedRecordIDs));
          if (excludedFirstCycleParamState === true) {
            if (Array.from(uniqueRecords.keys()).some((id) => !_excludedRecordIDs.includes(id))) dispatch(setExcludedFirstCycleParamState(false));
          }
        }}
        treeData={treeData}
      />
    </div>
  );
};

export default DashboardMeasuresSelectMode;
