import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { Col, Input, Row } from 'antd';
import { FC } from 'react';
import { useAppDispatch } from '../../app/hooks';
import Pagebreak from '../../routes/pdf/pageBreak';
import { saveParamsInDatabase } from '../../routes/pdf/pdfContainer';

export const ObservationsInput: FC<{ setObservations: ActionCreatorWithOptionalPayload<string | undefined, string>; observations: string | undefined; sessionID: string; authState: any; name: string }> = ({
  setObservations,
  observations,
  sessionID,
  authState,
  name,
}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Input.TextArea
        autoSize={true}
        size="small"
        variant="borderless"
        id="text"
        style={{ paddingLeft: '0', fontStyle: 'italic', marginTop: 0, marginBottom: 0, paddingBottom: 0 }}
        placeholder="You can type your observations here.."
        onChange={(e) => {
          dispatch(setObservations(e.target.value));
        }}
        onBlur={(e) => saveParamsInDatabase({ [name]: e.target.value }, sessionID, authState)}
        value={observations}
      ></Input.TextArea>
    </>
  );
};

export const Caption: FC<{ title: string; subtitle: string; setObservations: ActionCreatorWithOptionalPayload<string | undefined, string>; observations: string | undefined; sessionID: string; authState: any; name: string }> = (props) => {
  const { title, subtitle, setObservations, observations } = props;
  return (
    <Row justify="center">
      <Col xs={24} style={{ marginTop: 30 }}>
        <i>
          <b>{title}.</b>
          {subtitle}.
        </i>
        {observations && <Pagebreak />}

        <ObservationsInput setObservations={setObservations} observations={observations} sessionID={props.sessionID} authState={props.authState} name={props.name} />
      </Col>
    </Row>
  );
};
