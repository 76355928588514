import { Modal, ModalProps } from 'antd';

const ModalComponent: React.FC<ModalProps> = (props) => {
  const {
    children,
    open,
    confirmLoading,
    title,
    closable,
    onOk,
    onCancel,
    afterClose,
    centered,
    width,
    footer,
    okText,
    okType,
    cancelText,
    maskClosable,
    forceRender,
    okButtonProps,
    cancelButtonProps,
    destroyOnClose,
    style,
    wrapClassName,
    maskTransitionName,
    transitionName,
    className,
    getContainer,
    zIndex,
    styles,
    mask,
    keyboard,
    wrapProps,
    prefixCls,
    closeIcon,
    modalRender,
    focusTriggerAfterClose,
  } = props;

  return (
    <Modal
      open={open}
      confirmLoading={confirmLoading}
      title={title}
      closable={closable}
      onOk={onOk}
      onCancel={onCancel}
      afterClose={afterClose}
      centered={centered}
      width={width}
      footer={footer}
      okText={okText}
      okType={okType}
      cancelText={cancelText}
      maskClosable={maskClosable}
      forceRender={forceRender}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      destroyOnClose={destroyOnClose}
      style={style}
      wrapClassName={wrapClassName}
      maskTransitionName={maskTransitionName}
      transitionName={transitionName}
      className={className}
      getContainer={getContainer}
      zIndex={zIndex}
      styles={styles}
      mask={mask}
      keyboard={keyboard}
      wrapProps={wrapProps}
      prefixCls={prefixCls}
      closeIcon={closeIcon}
      modalRender={modalRender}
      focusTriggerAfterClose={focusTriggerAfterClose}
    >
      {children}
    </Modal>
  );
};

ModalComponent.defaultProps = {
  centered: true,
  width: '80%',
  closable: true,
  mask: true,
  style: { borderRadius: 5 },
};

export default ModalComponent;
