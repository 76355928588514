import { InputNumber } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectThresholdSignature, setThresholdSignature } from '../../../features/analysisConfig/analysisConfigSlice';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';

type ThresholdSignatureSettingsProps = {
  userInfo: UserClaimsWithTSDB | null;
};

const ThresholdSignatureSettings: React.FC<ThresholdSignatureSettingsProps> = ({ userInfo }) => {
  const dispatch = useAppDispatch();
  const threshold = useAppSelector(selectThresholdSignature);

  return (
    <div style={{ marginBottom: 30 }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <span>Threshold signature</span>
      </div>
      <InputNumber
        disabled={userInfo === null || userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl)}
        value={threshold}
        style={{ width: '100%', marginTop: 10, borderRadius: '2px' }}
        placeholder="1.5"
        onChange={(value: number | null) => {
          if (value !== null) dispatch(setThresholdSignature(value));
        }}
      />
    </div>
  );
};

export default ThresholdSignatureSettings;
