import { Col, DatePicker, Input, Row } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAbstract, selectAuthor, selectCompany, selectDivision, selectGenerationDate, setAbstract, setAuthor, setCompany, setGenerationDate, setDivision } from '../../features/analysisConfig/pdfSlice';
import { useOktaOrQueryAuth } from '../../utils';
import { saveParamsInDatabase } from './pdfContainer';

type CoordonatePdfProps = {};

const CoordonatePdf: React.FC<CoordonatePdfProps> = () => {
  const company = useAppSelector(selectCompany);
  const division = useAppSelector(selectDivision);
  const author = useAppSelector(selectAuthor);
  const generationDate = useAppSelector(selectGenerationDate);
  const abstract = useAppSelector(selectAbstract);
  const dispatch = useAppDispatch();

  const { sessionID } = useParams<{ sessionID: string }>();
  const { authState } = useOktaOrQueryAuth();

  return (
    <div id="coordonatePdf">
      <Row justify="start" style={{ marginBottom: 10 }}>
        <Col span={24}>
          <Row>
            <Col xs={24}>
              <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Col sm={4} span={24}>
                  <b>Company:</b>
                </Col>
                <Col>
                  <Input
                    size="small"
                    variant="borderless"
                    placeholder="Enter company name.."
                    onChange={(e) => dispatch(setCompany(e.target.value))}
                    onBlur={(e) => saveParamsInDatabase({ company: e.target.value }, sessionID, authState)}
                    value={company}
                  />
                </Col>
              </Row>
              <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Col sm={4} span={24}>
                  <b>Division:</b>
                </Col>
                <Col>
                  <Input
                    size="small"
                    variant="borderless"
                    placeholder="Enter division.."
                    onChange={(e) => dispatch(setDivision(e.target.value))}
                    onBlur={(e) => saveParamsInDatabase({ division: e.target.value }, sessionID, authState)}
                    value={division}
                  />
                </Col>
              </Row>
              <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Col sm={4} span={24}>
                  <b style={{ whiteSpace: 'nowrap' }}>Prepared by:</b>{' '}
                </Col>
                <Col>
                  <Input
                    size="small"
                    variant="borderless"
                    placeholder="Enter author's name.."
                    onChange={(e) => dispatch(setAuthor(e.target.value))}
                    onBlur={(e) => saveParamsInDatabase({ author: e.target.value }, sessionID, authState)}
                    value={author}
                  />
                </Col>
              </Row>
              <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Col sm={4} span={24}>
                  <b>Date:</b>
                </Col>
                <Col>
                  <DatePicker
                    style={{ paddingLeft: 7, paddingTop: 0 }}
                    format="YYYY/MM/DD"
                    allowClear={false}
                    variant="borderless"
                    onChange={(_date, dateString: string | string[]) => {
                      dispatch(setGenerationDate(dateString as string));
                      saveParamsInDatabase({ generationDate: new Date(dateString as string).getTime().toString() }, sessionID, authState);
                    }}
                    value={dayjs(generationDate, 'YYYY/MM/DD')}
                  />
                </Col>
              </Row>
              <div style={{ marginBottom: 10 }}>
                <div style={{ width: '100%' }}>
                  <b>Abstract:</b>
                </div>
                <div style={{ width: '100%' }}>
                  <Input.TextArea
                    size="small"
                    variant="borderless"
                    autoSize={true}
                    placeholder="Enter brief description of the work"
                    style={{ paddingLeft: 0, marginTop: 10, width: '100%' }}
                    onChange={(e) => dispatch(setAbstract(e.target.value))}
                    onBlur={(e) => saveParamsInDatabase({ abstract: e.target.value }, sessionID, authState)}
                    value={abstract}
                  ></Input.TextArea>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CoordonatePdf;
